import React from "react";
import * as CompareItemStyles from "../styles/CompareItem";
import * as Image from "../../../../../common-deprecated/styles/v2/lexus/globals/Image";
import Preloader from "../../../../../common-deprecated/styles/lexus/Preloader";
import * as Dropdown from "../../../../../common-deprecated/styles/lexus/Dropdown";
import SpecsWrapper from "./SpecsWrapper";
import CompareTable from "../../common/CompareTable";
import { useCompareUSCSelector } from "../../../redux/store";

type UsedCarCompareDetailsType = {
    isPdf?: boolean;
};

const UsedCompareDetails = ({ isPdf }: UsedCarCompareDetailsType): JSX.Element => {
    const cars = useCompareUSCSelector((state) => state.usedCarCompareExternalData.cars);
    const aspectRatio = useCompareUSCSelector((state) => state.compareUscSettings.aspectRatio);
    const objectFit = useCompareUSCSelector((state) => state.compareUscSettings.objectFit);
    const notAllCarsAreLoaded = !!cars.filter((car) => car.loading).length;

    return (
        <>
            <CompareTable
                items={cars}
                columnChild={(car) => (
                    <CompareItemStyles.Wrapper>
                        {isPdf && (
                            <Dropdown.Title showPointer={false} height={60}>
                                {car.product.brand.description} {car.product.model}
                            </Dropdown.Title>
                        )}
                        <CompareItemStyles.Placeholder objectFit={objectFit} isLoading={car.loading}>
                            {car.loading ? (
                                <Preloader />
                            ) : (
                                <Image.ImageFluid
                                    src={car.images[0]?.url}
                                    draggable={false}
                                    data-aspect-ratio={aspectRatio}
                                    alt={`${car.product.brand.description} ${car.product.model}`}
                                />
                            )}
                        </CompareItemStyles.Placeholder>
                    </CompareItemStyles.Wrapper>
                )}
            />

            {!notAllCarsAreLoaded && <SpecsWrapper />}
        </>
    );
};

export default UsedCompareDetails;
