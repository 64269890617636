import styled, { css } from "styled-components";
import { getDirection } from "../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { legalStyle } from "../../../../../common-deprecated/styles/v2/lexus/globals/Type";
import { PopoverButton } from "../../../../../common-deprecated/styles/v2/lexus/components/Tooltip";

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 0 ${theme.space[2]}px ${theme.space[2]}px;
    text-align: ${getDirection("left")};
`;

export const Title = styled.div`
    font-family: ${theme.fonts.book};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.platinumDark};

    ${PopoverButton} {
        margin-${getDirection("left")}: ${theme.space[1]}px;
        .icon::before {
            position: relative;
            top: 0;
            font-size: 1.3rem;
            line-height: 2.4rem;
            color: ${theme.colors.grey7};
        }
    }
`;

export const Value = styled.span<{ isLoading?: boolean; discountedPrice?: boolean }>`
    display: inline-block;
    position: relative;
    padding-${getDirection("right")}: ${theme.space[1]}px;
    font-family: ${theme.fonts.book};
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-decoration: ${({ discountedPrice }) => (discountedPrice ? "line-through" : "none")};
    color: ${({ discountedPrice }) => (discountedPrice ? theme.colors.platinumDark : theme.colors.black)};

    ${({ isLoading }) =>
        isLoading &&
        css`
            &:after {
                content: "0";
                background: ${theme.colors.silver};
                color: ${theme.colors.silver};
                width: 100%;
                position: absolute;
                ${getDirection("left")}: -5px;
                filter: blur(2px);
                width: 200px;
                ${getDirection("right")}: 0;
                transition: width 0.3s ease;
            }
        `};
`;

export const DualPriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ValueWrapper = styled.div`
    display: flex;
`;

export const KeyValue = styled.div<{ hasSmallFontSize?: boolean }>`
    ${Value} {
        font-size: ${({ hasSmallFontSize }) => (hasSmallFontSize ? "1.4rem" : "1.6rem")};
    }
`;

export const Suffix = styled.span`
    font-size: 1.6rem;
    color: ${theme.colors.black};
`;

export const Disclaimer = styled.button`
    padding: 0;
    background: transparent;
    border: none;

    svg, .icon {
        margin-${getDirection("left")}: ${theme.space[1]}px;
    }
`;

export const LegalText = styled.div`
    margin: ${theme.space[1]}px 0 0;
    text-align: ${getDirection("left")};
    text-indent: 0;
    color: ${theme.colors.midnight};
    ${legalStyle};
`;
