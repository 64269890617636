import React, { useEffect, useState } from "react";
import * as CollapseStyles from "../../../../../common-deprecated/styles/v2/lexus/custom/Collapse";
import * as CompareSpecsStyles from "../styles/CompareSpecs";
import EquipmentOrSpecPrice from "./EquipmentOrSpecPrice";
import EquipmentOrSpecValue from "./EquipmentOrSpecValue";
import ItemDescription from "./ItemDescription";
import {
    collapsibleListHasItemsToShow,
    showItemsForThisRow,
} from "../../../../../shared-logic/features/compare/utils/collapsibleListUtils";
import { ToggleIconWrapper } from "../../../../../common-deprecated/styles/v2/lexus/globals/IconWrapper";
import Icon from "../../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import CompareTable from "../../common/CompareTable";
import { CompareUSCLabel, CompareUSCLabelType, usedSpecsLabelMap } from "../../../utils/labels";
import { trackAemUsedCompareSectionExpand } from "../../../utils/tracking";
import { EquipmentSpecNameIdType } from "../../../utils/compareTypes";
import EfficiencyClassLabel from "../../../../shared-components/toyota/efficiency-class/EfficiencyClassLabel";
import EfficiencyClassLabelPdf from "../../../../shared-components/toyota/efficiency-class/EfficiencyClassLabelPdf";

type CollapsibleListPropsType = {
    header: string;
    items: EquipmentSpecNameIdType[];
    onlyShowDifferences?: boolean;
    showAllSpecs: boolean;
    cultureName: string;
    showPrices?: boolean;
    carColours?: string[];
    isPdf?: boolean;
    itemNameIsLabelKey?: boolean;
};

const CollapsibleList = (props: CollapsibleListPropsType): JSX.Element | null => {
    const { header, items, showAllSpecs, onlyShowDifferences, cultureName, showPrices } = props;
    const { carColours, isPdf, itemNameIsLabelKey } = props;

    const [isOpen, setIsOpen] = useState<boolean>(showAllSpecs);

    // When the showAllSpecs from the parent changes this overrides all local isOpen states
    useEffect(() => {
        setIsOpen(showAllSpecs);
    }, [showAllSpecs]);

    const isEfficiencyClass = (name: string): boolean => usedSpecsLabelMap.efficiencyClass === name;

    return collapsibleListHasItemsToShow(!!onlyShowDifferences, items) ? (
        <CollapseStyles.Wrapper>
            <CollapseStyles.Header
                onClick={() => {
                    setIsOpen(!isOpen);
                    if (!isOpen) trackAemUsedCompareSectionExpand(header);
                }}
            >
                <span>{header}</span>
                {!isPdf && (
                    <ToggleIconWrapper isDown={!isOpen}>
                        <Icon variant="chevronDown" />
                    </ToggleIconWrapper>
                )}
            </CollapseStyles.Header>
            {isOpen && (
                <CollapseStyles.Body noBorder noSpacing>
                    {items.map(
                        (item: EquipmentSpecNameIdType) =>
                            showItemsForThisRow(!!onlyShowDifferences, !!item.allValuesAreTheSame) && (
                                <CollapseStyles.Item key={item.id} isPdf={isPdf}>
                                    {item.values && item.values.length && (
                                        <CompareTable
                                            items={item.values}
                                            columnChild={(value, index) => (
                                                // wrapper div here for pdf that can't handle the flexbox wrapper
                                                <CompareSpecsStyles.InnerCol isPdf={isPdf}>
                                                    <CompareSpecsStyles.Label>
                                                        {itemNameIsLabelKey ? (
                                                            <CompareUSCLabel label={item.name as CompareUSCLabelType} />
                                                        ) : (
                                                            item.name
                                                        )}
                                                    </CompareSpecsStyles.Label>
                                                    <CompareSpecsStyles.Value>
                                                        {isEfficiencyClass(item.name) && value.eco ? (
                                                            isPdf ? (
                                                                <EfficiencyClassLabelPdf
                                                                    ecoInfo={value.eco}
                                                                    code={value.value as string}
                                                                />
                                                            ) : (
                                                                <EfficiencyClassLabel
                                                                    ecoInfo={value.eco}
                                                                    code={value.value as string}
                                                                />
                                                            )
                                                        ) : (
                                                            <>
                                                                <EquipmentOrSpecValue
                                                                    value={value}
                                                                    carColour={carColours && carColours[index]}
                                                                />
                                                                <EquipmentOrSpecPrice
                                                                    index={index}
                                                                    cultureName={cultureName}
                                                                    price={value.price}
                                                                    showPrices={!!showPrices}
                                                                />
                                                            </>
                                                        )}
                                                    </CompareSpecsStyles.Value>
                                                </CompareSpecsStyles.InnerCol>
                                            )}
                                        />
                                    )}

                                    <ItemDescription isPdf={isPdf} heightLimit={40} description={item.description} />
                                </CollapseStyles.Item>
                            ),
                    )}
                </CollapseStyles.Body>
            )}
        </CollapseStyles.Wrapper>
    ) : null;
};

export default CollapsibleList;
