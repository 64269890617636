import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { DisclaimerLocation } from "../../../../../common-deprecated/types/CommonTypes";
import { useCompareUSCSelector } from "../../../redux/store";
import { disclaimerSelector, UsedCarCompareDisclaimerType } from "../../../utils/disclaimerUtils";
import * as Styles from "../../../../../common-deprecated/styles/v2/lexus/components/InlineDisclaimer";

type Props = { disclaimerType: UsedCarCompareDisclaimerType; reference?: string };

const InlineDisclaimer: FC<Props> = ({ disclaimerType, reference }) => {
    const disclaimers = useCompareUSCSelector(disclaimerSelector, shallowEqual);
    const inlineDisclaimer = disclaimers.find(
        (disclaimer) =>
            disclaimer.location === DisclaimerLocation.Inline &&
            disclaimer.type === disclaimerType &&
            disclaimer.reference === reference,
    );

    if (!inlineDisclaimer) return null;

    return <Styles.Text mb={0}>{inlineDisclaimer.value}</Styles.Text>;
};

export default InlineDisclaimer;
