import * as React from "react";
import * as CompareEntryStyles from "../styles/CompareEntry";
import Icon from "../../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import CompareTable from "../../common/CompareTable";
import { CompareUSCLabel, CompareUSCLabelType } from "../../../utils/labels";
import { IconWrapper } from "../../../../../common-deprecated/styles/v2/lexus/globals/IconWrapper";
import { UsedParsedSpecEcoValueType, UsedCompareSpec } from "../../../utils/usedSpecsUtils";
import EfficiencyClassLabel from "../../../../shared-components/toyota/efficiency-class/EfficiencyClassLabel";

export type UsedQuickSpecType = {
    title: string;
    type: UsedCompareSpec;
    values: (string | number | UsedParsedSpecEcoValueType)[];
    carNotLoaded?: boolean;
    isMessageLabel?: boolean;
    suffix?: string;
    hasSmallFontSize?: boolean;
};

const UsedQuickSpecRow = (props: UsedQuickSpecType): JSX.Element => {
    const { title, values, isMessageLabel, carNotLoaded, suffix, hasSmallFontSize, type } = props;

    const getContentBySpecType = (
        specType: UsedCompareSpec,
        value: string | number | UsedParsedSpecEcoValueType,
    ): JSX.Element | string | number => {
        if (specType === UsedCompareSpec.Distance) {
            return <CompareUSCLabel label="usedCompareSpecDistanceValue" params={{ value: value as string }} />;
        }
        if (specType === UsedCompareSpec.EfficiencyClass) {
            const { letter, eco } = value as UsedParsedSpecEcoValueType;
            return <EfficiencyClassLabel ecoInfo={eco} code={letter} />;
        }
        return (
            (value as string) || (
                <IconWrapper iconColor="grey4">
                    <Icon variant="minus" />
                </IconWrapper>
            )
        );
    };

    return (
        <CompareTable
            items={values}
            columnChild={(value) => (
                <CompareEntryStyles.Wrapper>
                    <CompareEntryStyles.Title>
                        {isMessageLabel ? <CompareUSCLabel label={title as CompareUSCLabelType} /> : title}
                    </CompareEntryStyles.Title>
                    <CompareEntryStyles.ValueWrapper>
                        <CompareEntryStyles.KeyValue hasSmallFontSize={hasSmallFontSize}>
                            <CompareEntryStyles.Value isLoading={!!carNotLoaded}>
                                {getContentBySpecType(type, value)}
                            </CompareEntryStyles.Value>
                            {!carNotLoaded && suffix && <CompareEntryStyles.Suffix>{suffix}</CompareEntryStyles.Suffix>}
                        </CompareEntryStyles.KeyValue>
                    </CompareEntryStyles.ValueWrapper>
                </CompareEntryStyles.Wrapper>
            )}
        />
    );
};

export default UsedQuickSpecRow;
