import styled, { css } from "styled-components";
import {
    color,
    space,
    display,
    fontSize,
    lineHeight,
    DisplayProps,
    SpaceProps,
    FontSizeProps,
    LineHeightProps,
} from "styled-system";
import { getBreakpoint, getDirection, ThemeTypedColorProps } from "../../themes/common";

type BaseProps = { weight?: string } & SpaceProps &
    ThemeTypedColorProps &
    DisplayProps &
    FontSizeProps &
    LineHeightProps;

export const Base = css<BaseProps>`
    font-family: ${({ theme }) => theme.fontFamily.regular};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 1.1;
    text-align: ${getDirection("left")};
    text-indent: 0;
    color: ${({ theme }) => theme.color.dark};
    ${({ weight, theme }) =>
        weight === "regular" &&
        css`
            font-family: ${theme.fontFamily.regular};
        `};
`;

const OverrideBase = css`
    ${display};
    ${space};
    ${fontSize};
    ${lineHeight};
    ${color};
`;

export const H1 = styled.h1<BaseProps>`
    ${Base};
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: 32px;
    text-transform: uppercase;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 60px;
    }
`;

export const H2 = styled.h2<BaseProps>`
    ${Base};
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: 24px;
    text-transform: uppercase;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 40px;
    }
`;

export const H3 = styled.h3<BaseProps>`
    ${Base};
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: 18px;
    text-transform: uppercase;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 26px;
    }
`;

export const H4 = styled.h4<BaseProps>`
    ${Base};
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: 16px;
    text-transform: uppercase;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 18px;
    }
`;

/* not used yet, remove this comment when it is */

export const H5 = styled.h5<BaseProps>`
    ${Base};
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: 15px;
    text-transform: uppercase;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 17px;
    }
`;

export const H6 = styled.h6<BaseProps>`
    ${Base};
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: 12px;
    text-transform: uppercase;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 14px;
    }
`;

/* end not used yet */

export const P = styled.p<BaseProps>`
    ${Base};
    font-size: 14px;
    color: ${({ theme }) => theme.color.grey4};
    ${OverrideBase};
`;

export const Small = styled.p<BaseProps>`
    ${Base};
    font-size: 12px;
    color: ${({ theme }) => theme.color.grey4};
    ${OverrideBase};
`;

export const Medium = styled.p`
    ${Base};
    font-size: 1.3rem;
    color: ${({ theme }) => theme.color.grey3};
    ${OverrideBase};
`;

export const ItemTitle = styled.p<SpaceProps>`
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: 14px;
    line-height: 1.1;
    text-transform: uppercase;
    ${space};
`;

export const Strong = styled.strong`
    ${Base};
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-weight: normal;
    ${OverrideBase}
`;

export const Light = styled.strong`
    ${Base};
    font-family: ${({ theme }) => theme.fontFamily.light};
    ${OverrideBase};
`;
