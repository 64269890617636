import styled from "styled-components";
import { getBreakpoint } from "../../../../themes/common";
import { theme } from "../theme/lexusTheme";

export const Horizontal = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space[2]}px;
    width: 100%;
    @media ${getBreakpoint("up", "md")} {
        flex-direction: row;
    }
`;

export const Vertical = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space[2]}px;
    width: 100%;
`;
