import styled from "styled-components";
import { theme } from "../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";

export const Price = styled.span``;

export const Discount = styled.span`
    background-color: ${theme.colors.midnight};
`;

export const LineThrough = styled.span`
    text-decoration: line-through;
`;
