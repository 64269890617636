import styled, { css } from "styled-components";
import { getDirection } from "../../../../themes/common";
import { theme } from "../theme/lexusTheme";

export const Wrapper = styled.div`
    display: inline-flex;
    padding-${getDirection("left")}: ${theme.space[3]}px;
    padding-${getDirection("right")}: ${theme.space[6]}px;
    border-bottom: 1px solid ${theme.colors.grey2};
    @media print {
        display: none;
    }
`;

export const Item = styled.div<{ active?: boolean }>`
    display: flex;
    position: relative;
    top: 1px;
    flex: 1;
    padding-bottom: ${theme.space[2]}px;
    font-family: ${theme.fonts.book};
    font-size: 1.6rem;
    line-height: 2.4rem;
    white-space: nowrap;
    border-bottom: 1px solid ${theme.colors.silver};
    transition: border 0.2s;
    cursor: pointer;

    &:first-child {
        margin-${getDirection("right")}: ${theme.space[3]}px;
    }

    ${({ active }) =>
        active &&
        css`
            font-family: ${theme.fonts.regular};
            border-color: ${theme.colors.azure};
        `};
`;
