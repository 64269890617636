import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { theme } from "../theme/lexusTheme";

export const Separator = styled.hr.attrs((props) => ({
    className: "separator",
}))<SpaceProps>`
    width: 100%;
    height: 1px;
    border: none;
    border-bottom: 1px solid;
    border-color: ${theme.colors.platinumLight};
    ${space};
`;

export default Separator;
