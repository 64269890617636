import React from "react";
import * as Type from "../../../../common-deprecated/styles/lexus/Type";
import useMaxNumberToCompare from "../../../../shared-logic/features/compare/hooks/useMaxNumberToCompare";
import * as Styles from "./styles/CompareHeader";
import * as CompareItemStyles from "./styles/CompareItem";
import useUsedCarHeader from "../../hooks/useUsedCarHeader";
import CompareTable from "../common/CompareTable";
import { useCompareUSCSelector } from "../../redux/store";
import { CompareUSCLabel, useCompareUSCLabel } from "../../utils/labels";
import { IconWrapper, ToggleIconWrapper } from "../../../../common-deprecated/styles/v2/lexus/globals/IconWrapper";
import Icon, { IconChevronDown } from "../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import ButtonPrimary from "../../../../common-deprecated/components/aem/lexus/button/ButtonPrimary";
import ButtonTertiary from "../../../../common-deprecated/components/aem/lexus/button/ButtonTertiary";
import { LoadedUsedCompareCarType } from "../../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import DualCurrencyDisclaimer from "../../../../common-deprecated/components/lexus/DualCurrencyDisclaimer";

type UsedCompareHeaderPropsType = {
    expanded: boolean;
    toggleExpanded: () => void;
};

const UsedCarHeader = (props: UsedCompareHeaderPropsType): JSX.Element => {
    const { expanded, toggleExpanded } = props;
    const cars = useCompareUSCSelector(
        ({ usedCarCompareExternalData }) => usedCarCompareExternalData.cars,
    ) as LoadedUsedCompareCarType[];
    const maxNumberToCompare = useMaxNumberToCompare();
    const titleLabel = useCompareUSCLabel("compareHeaderChooseUpToXCars", {
        maxNumberOfCars: maxNumberToCompare,
    });

    const { trackedToggleExpanded, trackedClearAll, trackedClearCar } = useUsedCarHeader(
        expanded,
        toggleExpanded,
        cars.length,
    );

    return (
        <Styles.Wrapper expanded={expanded}>
            <Styles.Actions align="left">
                {(cars.length > 1 || expanded) && (
                    <ButtonPrimary hasIcon onClick={trackedToggleExpanded}>
                        <ToggleIconWrapper isDown={expanded}>
                            <IconChevronDown />
                        </ToggleIconWrapper>
                        <CompareUSCLabel label={expanded ? "compareHeaderHide" : "compareHeaderTitle"} />
                    </ButtonPrimary>
                )}
            </Styles.Actions>

            <Styles.ItemWrapper>
                <CompareTable
                    items={cars}
                    columnChild={(car, index) => (
                        <CompareItemStyles.Border
                            showCursorPointer={expanded}
                            hideBorder={index + 1 === maxNumberToCompare}
                        >
                            <Styles.MaskedPreloader show={car.loading} />
                            <CompareItemStyles.Title>
                                <Type.Strong>{car.product.brand.description}</Type.Strong> {car.product.model}
                                <CompareItemStyles.Subtitle>{car.product.versionName}</CompareItemStyles.Subtitle>
                            </CompareItemStyles.Title>
                            <CompareItemStyles.IconHolder>
                                <CompareItemStyles.CloseButton
                                    onClick={() => trackedClearCar(index, car.trackingProductName)}
                                >
                                    <IconWrapper>
                                        <Icon variant="close" />
                                    </IconWrapper>
                                </CompareItemStyles.CloseButton>
                            </CompareItemStyles.IconHolder>
                        </CompareItemStyles.Border>
                    )}
                    placeholder={() => (
                        <CompareItemStyles.Border showCursorPointer={expanded} hideBorder>
                            <CompareItemStyles.Title>{titleLabel}</CompareItemStyles.Title>
                        </CompareItemStyles.Border>
                    )}
                />
            </Styles.ItemWrapper>

            <Styles.Actions align="right">
                <ButtonTertiary onClick={trackedClearAll}>
                    <CompareUSCLabel label="compareHeaderClearAll" />
                </ButtonTertiary>
            </Styles.Actions>
            <Styles.DisclaimerWrappers>
                <DualCurrencyDisclaimer variantOutline />
            </Styles.DisclaimerWrappers>
        </Styles.Wrapper>
    );
};

export default UsedCarHeader;
