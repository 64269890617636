import React, { FC } from "react";
import useIntegratedUsedCompare from "../hooks/useIntegratedUsedCompare";
import UsedCompareLexus from "./UsedCompareLexus";

const IntegratedUsedCompareLexus: FC = () => {
    const { fetching } = useIntegratedUsedCompare();

    return fetching ? null : <UsedCompareLexus />;
};

export default IntegratedUsedCompareLexus;
