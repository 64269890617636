import styled, { css } from "styled-components";
import { getBreakpoint, getDirection } from "../../../../themes/common";
import { theme as lexusTheme } from "../theme/lexusTheme";
import * as Button from "../components/Button";
import { IconWrapper } from "../globals/IconWrapper";

export const Wrapper = styled.section<{ height?: number | null; top?: number | null }>`
    ${({ height, top }) =>
        height &&
        top &&
        css`
            position: fixed;
            height: ${height}px;
            top: ${top}px;
            left: 0;
            right: 0;
            z-index: ${lexusTheme.zIndex.overlay};
            @media ${getBreakpoint("up", "md")} {
                height: initial;
                top: 100%;
            }
        `}
    color: ${lexusTheme.colors.azure};
    background-color: ${lexusTheme.colors.silver};
    overflow: hidden;
`;

export const Header = styled.header`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    height: 110px;
    padding: ${lexusTheme.space[3]}px;
    font-family: ${lexusTheme.fonts.regular};
    font-size: 1.4rem;
    line-height: 2.3rem;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 100;

    ${IconWrapper} {
        margin-${getDirection("right")}: ${lexusTheme.space[2]}px;
    }
`;

export const HeaderButton = styled.div`
    display: flex;
`;

export const HeaderLabel = styled.span.attrs(() => ({
    className: "l-delta-text",
}))`
    display: inline-block;
    margin: 0 auto;
`;

export const Body = styled.div`
    transition: transform 0.25s ease-in-out;
`;

export const Panel = styled.div<{ top?: number | null }>`
    position: relative;
    width: 100vw;
    overflow: hidden;
`;

// total width and left offset can are calculated dynamically so this component can be used with both 2 and 3 panels
// 184px is the height of the SelectPane Footer on mobile
export const Panels = styled.div<{
    currentPanel?: number;
    panelCount?: number;
    height?: number | null;
    compareHeaderHeight?: number | null;
}>`
    display: flex;
    width: ${({ panelCount }) => (panelCount || 1) * 100}%;
    transform: ${({ currentPanel, panelCount, theme }) =>
        `translateX(${theme.isRTL ? "" : "-"}${((currentPanel || 0) * 100) / (panelCount || 1)}%)`};
    transition: transform 0.25s ease-in-out;

    ${({ compareHeaderHeight, height }) =>
        compareHeaderHeight &&
        css`
            ${Panel} {
                /* 166px is the height of the footer */
                max-height: calc(${height}px - ${compareHeaderHeight}px - 166px);
                @media ${getBreakpoint("up", "md")} {
                    max-height: 250px;
                }
            }
        `}
`;

export const PanelContent = styled.div`
    position: relative;
    height: 100%;
    padding-top: 82px;
`;

export const PanelScroll = styled.div`
    height: 100%;
    font-size: 1.3rem;
    overflow-y: auto;
`;

export const PanelTitle = styled.div`
    padding: 25px 20px 22px;
    font-family: ${lexusTheme.fonts.regular};
    font-size: 1.7rem;
    color: ${lexusTheme.colors.platinumMedium};
    border-bottom: 1px solid ${lexusTheme.colors.platinumMedium};
    cursor: pointer;
    
    &::before {
        content: "-";
        padding-${getDirection("right")}: ${lexusTheme.space[2]}px;
    }

    &::after {
        content: "-";
        padding-${getDirection("left")}: ${lexusTheme.space[2]}px;
    }
`;

export const PanelStep = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${lexusTheme.space[1]}px ${lexusTheme.space[3]}px;
    font-family: ${lexusTheme.fonts.regular};
    font-size: 1.7rem;
    color: ${lexusTheme.colors.platinumMedium};
    background-color: ${lexusTheme.colors.azure};
    border-bottom: 1px solid ${lexusTheme.colors.platinumMedium};
`;

export const PanelStepTitle = styled.div`
    color: ${lexusTheme.colors.black};
`;

export const PanelItem = styled.button<{ selected?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${lexusTheme.space[3]}px;
    font-family: ${lexusTheme.fonts.regular};
    font-size: 1.7rem;
    color: ${lexusTheme.colors.azure};
    border-bottom: 1px solid ${lexusTheme.colors.platinumMedium};
    &:hover {
        background-color: ${lexusTheme.colors.platinumLight};
    }
    ${({ selected }) =>
        selected &&
        css`
            font-family: ${lexusTheme.fonts.bold};
            background-color: ${lexusTheme.colors.platinumLight};
        `};
`;

export const Footer = styled.footer`
    padding: ${lexusTheme.space[3]}px;
    background-color: transparent !important;

    ${Button.ButtonSecondary} {
        width: 100%;
        margin-top: ${lexusTheme.space[2]}px;
    }
`;
