import React from "react";
import * as Compare from "./styles/CompareStyles";
import { Separator } from "../../../../common-deprecated/styles/v2/lexus/globals/Separator";
import Icon from "../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import { printPdf } from "../../../../common-deprecated/utils/pdfUtils";
import { useCompareUSCSelector } from "../../redux/store";
import { CompareUSCLabel } from "../../utils/labels";
import { getUscComparePdfLink } from "../../utils/uscCompareUtils";
import { trackAemUsedComparePdfClick } from "../../utils/tracking";
import ButtonWithIcon from "../../../../common-deprecated/components/aem/lexus/button/ButtonWithIcon";
import * as Text from "../../../../common-deprecated/styles/v2/lexus/utilities/Text";

const PdfLink = (): JSX.Element => {
    const commonSettings = useCompareUSCSelector((state) => state.commonSettings);

    const sharePdf = (): void => {
        const url = getUscComparePdfLink(commonSettings);
        trackAemUsedComparePdfClick();
        // This opens the PDF in new tab
        printPdf(url, commonSettings);
    };

    return (
        <div>
            <Separator />
            <Compare.PdfSection>
                {/* Button gets hidden by applying base style in the template */}
                <ButtonWithIcon hasIconLeft onClick={() => sharePdf()}>
                    <Text.IconButton>
                        <CompareUSCLabel label="compareBodySaveAsPdf" />
                    </Text.IconButton>
                    <Icon variant="pdf" />
                </ButtonWithIcon>
            </Compare.PdfSection>
        </div>
    );
};

export default PdfLink;
