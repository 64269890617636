import styled from "styled-components";
import { getBreakpoint, getDirection } from "../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { legalStyle } from "../../../../../common-deprecated/styles/v2/lexus/globals/Type";

export const Wrapper = styled.div`
    margin: ${theme.space[3]}px 0;
    padding: ${theme.space[3]}px;
    text-align: ${getDirection("left")};
    background-color: ${theme.colors.mist};
    ${legalStyle};
`;

export const Item = styled.div`
    display: inline-flex;
    align-items: center;
    width: 100%;
    max-width: 50%;
    .icon {
        position: relative;
        top: 0;
        margin-${getDirection("right")}: ${theme.space[1]}px;
        /* Fix icon sizes */
        &::before {
            font-size: 1.8rem;
        }
    }

    /* Fix icon sizes */
    .icon-check,
    .icon-circle {
        &::before {
            font-size: 1.2rem;
        }
    }

    @media ${getBreakpoint("up", "md")} {
        width: auto;
        max-width: none;
        margin-${getDirection("right")}: ${theme.space[3]}px;
    }
`;
