import React from "react";
import { shallowEqual } from "react-redux";
import { useCompareUSCSelector } from "../../../redux/store";
import { getDisclaimerId, getDisclaimerSelector, UsedCarCompareDisclaimerType } from "../../../utils/disclaimerUtils";
import { scrollTargetIntoView } from "../../../../../common-deprecated/utils";
import Popover from "../../../../../common-deprecated/components/popover/aem/lexus/Popover";
import NumberedDisclaimerIcon from "../../../../../common-deprecated/styles/v2/lexus/custom/NumberedDisclaimerIcon";
import { useAemNavOffset } from "../../../../../common-deprecated/hooks";
import { DisclaimerLocation } from "../../../../../common-deprecated/types/CommonTypes";

type Props = {
    type: UsedCarCompareDisclaimerType;
    isLight?: boolean;
    reference?: string;
};

/**
 * Render a disclaimer icon, which is an info popover icon or footnote depending on config.
 * If you want to add a new disclaimer add support for the disclaimer in disclaimerSelector first.
 */
const DisclaimerIconWrapper = ({ type, reference, isLight }: Props): JSX.Element | null => {
    const disclaimer = useCompareUSCSelector(getDisclaimerSelector(type, reference), shallowEqual);
    const siteNavOffset = useAemNavOffset();

    if (!disclaimer) return null;

    switch (disclaimer.location) {
        case DisclaimerLocation.Inline:
        case DisclaimerLocation.InfoText:
            // Inline and infoText disclaimers are rendered elsewhere, no icon necessary.
            return null;
        case DisclaimerLocation.InfoIcon:
            return <Popover content={disclaimer.value} useHTML={disclaimer.containsHtml} showOnClick forceShowModal />;
        case DisclaimerLocation.Footnote:
            return (
                <NumberedDisclaimerIcon
                    isLight={isLight}
                    onClick={(event) => {
                        event.preventDefault();
                        scrollTargetIntoView(`#${getDisclaimerId(disclaimer)}`, siteNavOffset, true);
                    }}
                    hasMousePointer
                >
                    {disclaimer.number}
                </NumberedDisclaimerIcon>
            );
    }
};

export default DisclaimerIconWrapper;
