import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { getBreakpoint, getDirection } from "../../../../../common-deprecated/themes/common";
import * as Button from "../../../../../common-deprecated/styles/v2/lexus/components/Button";
import * as Toggle from "../../../../../common-deprecated/styles/v2/lexus/components/Toggle";
import * as Text from "../../../../../common-deprecated/styles/v2/lexus/utilities/Text";
import * as CompareStyles from "./CompareStyles";
import { h4Style, bodyStyle } from "../../../../../common-deprecated/styles/v2/lexus/globals/Type";
import { theme } from "../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";

export const Wrapper = styled(CompareStyles.Row)<SpaceProps>`
    flex-direction: column;
    ${space};
`;

export const Header = styled.header`
    margin-bottom: ${theme.space[4]}px;

    ${Toggle.Wrapper} {
        width: 100%;
        @media ${getBreakpoint("up", "md")} {
            width: auto;
        }
    }

    ${Button.ButtonWithIcon} {
        ${Text.IconButton} {
            margin-${getDirection("left")}: 0;
            padding-${getDirection("left")}: 0;
        }
    }
`;

export const Title = styled.h2`
    text-align: ${getDirection("left")};
    text-transform: "uppercase";
    color: ${theme.colors.midnight};
    ${h4Style};
`;

export const Description = styled.div<{ isPdf?: boolean } & SpaceProps>`
    position: relative;
    padding: ${theme.space[3]}px 0;
    ${bodyStyle};
    ${space};

    @media ${getBreakpoint("up", "md")} {
        display: ${({ isPdf }) => (isPdf ? "block" : "flex")};
        align-items: center;
        justify-content: space-between;
        text-align: ${getDirection("left")};
    }

    ${Text} {
        position: relative;
        flex-shrink: 1;
        margin-bottom: 20px;
        padding-left: 80px;
        @media ${getBreakpoint("up", "md")} {
            margin-bottom: 0;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -40px;
            margin-right: 6px;
        }
    }
`;
