import * as React from "react";
import * as ComparePriceStyles from "../styles/ComparePrice";
import { IconTag } from "../../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import { EquipmentSpecNameIdValueType } from "../../../../../common-deprecated/features/compare/utils/newCarCompareTypes";
import useUsedEquipmentOrSpecPrice from "../../../hooks/useUsedEquipmentOrSpecPrice";
import { IconWrapper } from "../../../../../common-deprecated/styles/v2/lexus/globals/IconWrapper";
import { formatAEMCashPrice } from "../../../../../common-deprecated/settings/utils/financeSettingUtilsAem";

type ComparePriceAtomPropsType = {
    index: number;
    price?: EquipmentSpecNameIdValueType["price"];
    cultureName: string;
    showPrices: boolean;
};

const EquipmentOrSpecPrice = (props: ComparePriceAtomPropsType): JSX.Element | null => {
    const { cultureName, price, showPrices, index } = props;

    const { normalPrice, discountedPrice, priceConfig } = useUsedEquipmentOrSpecPrice(index, price);

    if (!normalPrice || !showPrices) {
        return null;
    }

    if (normalPrice === discountedPrice) {
        return (
            <ComparePriceStyles.Price>
                <span dangerouslySetInnerHTML={{ __html: formatAEMCashPrice(normalPrice, cultureName, priceConfig) }} />
            </ComparePriceStyles.Price>
        );
    } else {
        return (
            <>
                <IconWrapper iconColor="black">
                    <IconTag />
                </IconWrapper>
                <ComparePriceStyles.LineThrough>
                    <span
                        dangerouslySetInnerHTML={{ __html: formatAEMCashPrice(normalPrice, cultureName, priceConfig) }}
                    />
                </ComparePriceStyles.LineThrough>
                <ComparePriceStyles.Discount>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: formatAEMCashPrice(discountedPrice!, cultureName, priceConfig),
                        }}
                    />
                </ComparePriceStyles.Discount>
            </>
        );
    }
};

export default EquipmentOrSpecPrice;
