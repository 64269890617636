import styled, { css } from "styled-components";
import { theme } from "../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { getDirection } from "../../../../../common-deprecated/themes/common";
import { ctaStyle } from "../../../../../common-deprecated/styles/v2/lexus/globals/Type";
import * as Tooltip from "../../../../../common-deprecated/styles/v2/lexus/components/Tooltip";

export const Wrapper = styled.div``;

export const Header = styled.header``;

export const Title = styled.h3.attrs(() => ({
    className: "l-gamma-text",
}))`
    margin: ${theme.space[3]}px 0;
    text-align: ${getDirection("left")};
    text-transform: uppercase;
`;

export const InnerCol = styled.div<{ isPdf?: boolean }>`
    width: 100%;
    padding: ${({ isPdf }) => (isPdf ? `${theme.space[1]}px 0` : `${theme.space[2]}px ${theme.space[3]}px`)};
`;

export const Label = styled.div.attrs(() => ({
    className: "l-milli-text",
}))`
    margin-bottom: ${theme.space[2]}px;
    text-align: ${getDirection("left")};
    color: ${theme.colors.platinumDark};
`;

type DescriptionType = { open: boolean; showReadMore: boolean; heightLimit: number; fullHeight: number };

export const Description = styled.span.attrs(() => ({
    className: "l-milli-text",
}))<DescriptionType>`
    margin-top: ${theme.space[2]}px;
    color: ${theme.colors.platinumDark};

    ${({ showReadMore, heightLimit }) =>
        showReadMore &&
        css`
            display: block;
            position: relative;
            max-height: ${heightLimit}px;
            overflow: hidden;
            transition: max-height 0.3s;

            &:after {
                content: "";
                position: absolute;
                background: linear-gradient(transparent, white);
                transition: opacity 0.3s;
                opacity: 1;
                height: 40px;
                bottom: 0;
                left: 0;
                right: 0;
            }
        `};
    ${({ open, fullHeight }) =>
        open &&
        // 20px padding just to be safe
        css`
            display: block;
            padding-bottom: ${theme.space[2]}px;
            max-height: ${fullHeight + 20}px !important;
            overflow: hidden;
            &::after {
                opacity: 0;
                display: none;
            }
        `};
`;

export const Value = styled.div.attrs(() => ({
    className: "l-base-text",
}))`
    position: relative; /* for tooltip */
    min-height: 25px;
    text-align: ${getDirection("left")};
    color: ${theme.colors.midnight};

    span {
        padding-${getDirection("left")}: ${theme.space[1]}px;
        font-family: ${theme.fonts.book};
        font-size: 1.3rem;
        color: ${theme.colors.midnight};
    }

    ${Tooltip.Wrapper} {
        width: 400px;
    }
`;

export const ReadMoreLess = styled.button.attrs(() => ({
    className: "l-milli-text",
}))`
    display: flex;
    padding: 0 ${theme.space[3]}px ${theme.space[2]}px;
    padding-${getDirection("left")}: 0;
    text-transform: uppercase;
    color: ${theme.colors.azure};
    border: none;
    ${ctaStyle};

    &:hover {
        color: ${theme.colors.azureHover};
    }
`;
