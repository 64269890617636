import styled, { css } from "styled-components";
import { display, DisplayProps, width, WidthProps, justifyContent, JustifyContentProps } from "styled-system";
import { theme } from "../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { getDirection } from "../../../../../common-deprecated/themes/common";
import * as Type from "../../../../../common-deprecated/styles/lexus/Type";
import Preloader from "../../../../../common-deprecated/styles/v2/lexus/custom/Preloader";
import { Backdrop } from "../../../../../common-deprecated/styles/v2/lexus/components/Backdrop";
import { h5Style, captionStyle } from "../../../../../common-deprecated/styles/v2/lexus/globals/Type";
import { UscObjectFitType } from "../../../../shared-logic/types/UscCommonTypes";

export const Wrapper = styled.article`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: space-between;
    width: 100%;
    padding: 0 ${theme.space[2]}px;
`;

export const SpecWrapper = styled.article<DisplayProps & WidthProps & JustifyContentProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    ${display};
    ${width};
    ${justifyContent};
    ${Preloader} {
        position: absolute;
        ${getDirection("left")}: 0;
    }
`;

export const Border = styled(Wrapper)<{ showCursorPointer?: boolean; hideBorder?: boolean }>`
    position: relative;
    justify-content: flex-start;
    border-${getDirection("right")}: ${({ hideBorder }) =>
    `${hideBorder ? 0 : 1}px solid ${theme.colors.platinumLight}`};
    cursor: ${({ showCursorPointer }) => (showCursorPointer ? "pointer" : "default")};
    ${Backdrop} {
        z-index: ${theme.zIndex.fixed};
    }
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ImageWrapper = styled.div`
    display: inline-block;
    width: 100px;
    height: 47px;
    @supports (object-fit) {
        object-fit: contain;
        object-position: center;
    }
`;

export const Title = styled.div`
    width: 100%;
    padding-${getDirection("right")}: ${theme.space[3]}px;
    color: ${theme.colors.azure};
    overflow: hidden;

    ${Type.Strong} {
        color: ${theme.colors.azure};
    }
`;

export const CarTitle = styled.div`
    text-overflow: ellipsis;
    color: ${theme.colors.azure};
    ${h5Style};
`;

export const Subtitle = styled.div`
    color: ${theme.colors.steel};
    ${captionStyle};
    /* stylelint-disable */
    display: -webkit-box;
    max-width: 100%;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    /* stylelint-enable */
    hyphens: auto;
`;

export const CloseButton = styled.div`
    color: ${theme.colors.azure};
`;

export const IconHolder = styled.div`
    display: flex;
    position: absolute;
    top: 50%;
    ${getDirection("right")}: ${theme.space[2]}px;
    align-items: flex-start;
    width: 24px;
    color: ${theme.colors.azure};
    transform: translateY(-50%);
    cursor: pointer;
    .icon {
        padding: 0;
        line-height: 1;
    }
`;

export const Placeholder = styled.div<{ objectFit: UscObjectFitType; isLoading?: boolean }>`
    position: relative;
    margin: 0;
    overflow: hidden;

    &::before {
        height: 0;
        padding-top: 56.25%; /* Aspect ratio 16:9 */
        content: "";
    }

    img {
        width: auto;
        max-height: 100%;
        object-fit: ${({ objectFit }) => objectFit};
    }

    svg {
        width: 100%;
        height: 100%;
        max-height: 128px;
        opacity: 0.125;
    }

    ${({ isLoading }) =>
        isLoading &&
        css`
            padding-top: 52%;
        `}
`;

export const PrevImage = styled.div<{ show?: boolean; transition?: string }>`
    transition: ${({ transition }) => transition};
    opacity: ${({ show }) => (show ? 1 : 0)};
`;
