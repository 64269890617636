import styled, { css } from "styled-components";
import { theme } from "../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { getBreakpoint, getDirection } from "../../../../../common-deprecated/themes/common";
import { ButtonPrimary, ButtonTertiary } from "../../../../../common-deprecated/styles/v2/lexus/components/Button";
import Preloader from "../../../../../common-deprecated/styles/v2/lexus/custom/Preloader";
import * as SelectPane from "../../../../../common-deprecated/styles/v2/lexus/custom/SelectPane";
import * as CompareStyles from "./CompareStyles";

export const Wrapper = styled.div<{ expanded?: boolean }>`
    display: flex;
    position: fixed;
    top: 0;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: ${theme.space[3]}px;
    color: ${theme.colors.azure};
    background-color: ${theme.colors.silver};
    transform: translateY(-100%);
    transition: border-radius 0.3s, transform 0.5s;
    z-index: ${theme.zIndex.pageoverlayerExpansion};
    &::before {
        position: absolute;
        top: -48px;
        left: 0;
        width: 100%;
        height: 48px;
        content: "";
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    }
    ${({ expanded }) =>
        expanded &&
        css`
            position: fixed;

            @supports (position: sticky) {
                position: sticky;
            }

            top: 0;
            left: 0;
            right: 0;
            transform: translateY(0);
            &::before {
                display: none;
            }
        `};

    @media (min-width: 1200px) {
        flex-direction: row;
    }

    h1 {
        font-family: ${theme.fonts.bold};
        font-size: 1.7rem;
        letter-spacing: 0;
        line-height: 1.9rem;
        text-align: ${getDirection("left")};
        color: ${theme.colors.azure};
    }

    ${SelectPane.Wrapper} {
        @media ${getBreakpoint("up", "md")} {
            position: absolute;
            top: calc(100% + ${theme.space[3]}px);
        }
    }
`;

// TODO: css animation breaks because of performance
export const MaskedPreloader = styled(Preloader)<{ show?: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: ${theme.colors.silver};
    transition: opacity 0.3s;
    opacity: 0;
    ${({ show }) =>
        show &&
        css`
            opacity: 0.7;
        `};
`;

export const Fixed = styled(Wrapper)<{ isHeaderFixed?: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${theme.colors.azure};
    transform: translateY(${({ isHeaderFixed }) => (isHeaderFixed ? "0" : "-130px")});
    transition: transform 0.2s ease-in-out;
    z-index: ${theme.zIndex.pageoverlayerExpansion};
`;

export const Actions = styled.div<{ hidden?: boolean; align?: "left" | "right" }>`
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;
    align-self: center;
    width: 50%;
    visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};

    ${({ align }) =>
        align === "right"
            ? css`
                  justify-content: flex-end;
                  order: 2;
                  @media (min-width: 1200px) {
                      position: absolute;
                      top: ${theme.space[3]}px;
                      ${getDirection("right")}: ${theme.space[3]}px;
                      order: 3;
                  }
              `
            : css`
                  justify-content: flex-start;
                  order: 1;
                  @media (min-width: 1200px) {
                      position: absolute;
                      top: ${theme.space[3]}px;
                      ${getDirection("left")}: ${theme.space[3]}px;
                  }
              `};

    @media (min-width: 1200px) {
        width: auto;
    }

    ${ButtonPrimary} {
        width: auto;
        max-height: 32px;
        /* Override some CSS styles :( */
        button {
            padding: 0 1.6rem !important;
            > .btn-text {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
            }
            &::before {
                display: none;
            }
        }
        .icon {
            margin: 0 !important;
            padding: 0 !important;
            color: ${theme.colors.white};
        }
    }

    ${ButtonTertiary} {
        display: flex;
        justify-content: flex-end;
        /* dirty quickhack / fix because RTL styles are broken on the buttons */
        button {
            padding: 0 !important;
            line-height: 1;
        }
        .btn-text > .btn-text {
            display: inline-block;
            padding-bottom: 0.7rem;
            line-height: 1.2;
            border-bottom: 0.2rem solid ${theme.colors.azure};
        }
        .btn-text::before {
            display: none !important;
        }
    }
`;

export const ItemWrapper = styled(CompareStyles.Container)`
    position: relative;
    order: 3;
    margin: ${theme.space[3]}px 0 0;

    @media (min-width: 1200px) {
        order: 2;
        margin: 0 auto;
    }
`;

export const DisclaimerWrappers = styled.div`
    order: 4;
    width: 100%;
    &:not(:empty) {
        margin-top: ${theme.space[2]}px;
    }
`;
