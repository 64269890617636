import styled from "styled-components";

export const ImageFluid = styled.img`
    max-width: 100%;
    height: auto;

    &[data-aspect-ratio="16:9"] {
        aspect-ratio: 16/9;
        object-fit: cover;
    }

    &[data-aspect-ratio="4:3"] {
        aspect-ratio: 4/3;
        object-fit: cover;
    }
`;

export default ImageFluid;
