import React from "react";
import { useDispatch } from "react-redux";
import PdfLink from "./PdfLink";
import * as Compare from "./styles/CompareStyles";
import * as CompareItemStyles from "./styles/CompareItem";
import Text from "../../../../common-deprecated/styles/lexus/Text";
import CompareTable from "../common/CompareTable";
import { CompareUSCDispatchType, useCompareUSCSelector } from "../../redux/store";
import { CompareUSCLabel } from "../../utils/labels";
import { getUscVehicleUrlInfoFromCompareResult } from "../../utils/usedSpecsUtils";
import { getUscUrl } from "../../../shared-logic/utils/uscUtils";
import { trackAemUsedCompareCtaClick } from "../../utils/tracking";
import ButtonPrimary from "../../../../common-deprecated/components/aem/lexus/button/ButtonPrimary";
import ButtonSecondary from "../../../../common-deprecated/components/aem/lexus/button/ButtonSecondary";
import { LoadedUsedCompareCarType } from "../../../../common-deprecated/features/compare/utils/usedCarCompareTypes";

import { getContactDealerCta } from "../../utils/ctaUtils";
import * as Preloader from "../../../../common-deprecated/styles/lexus/Preloader";
import useLocalContactDealerClick from "../../../shared-logic/hooks/useLocalContactDealerClick";
import { UscContext } from "../../../shared-logic/types/UscCommonTypes";

const CarCTA = ({ car }: { car: LoadedUsedCompareCarType }): JSX.Element | null => {
    const dispatch = useDispatch<CompareUSCDispatchType>();
    const commonSettings = useCompareUSCSelector((state) => state.commonSettings);
    const settings = useCompareUSCSelector((state) => state.compareUscSettings);

    const getCarUrl = (pageUrl: string): string => {
        const urlInfo = getUscVehicleUrlInfoFromCompareResult(car);
        return getUscUrl(commonSettings, pageUrl, "used", urlInfo);
    };

    const localContactDealerClick = useLocalContactDealerClick({
        // Compare only supports used cars
        context: UscContext.Used,
        dealer: car.dealer,
        vehicle: car,
        detailPageUrl: settings.urlUsedCarsDetailPage,
        localContactDealerFormUrl: settings.localContactDealerFormUrl,
        trackingFn: () => trackAemUsedCompareCtaClick("uc-vehicle-request"),
    });

    const { label, click } = getContactDealerCta(
        car,
        settings.urlUsedCarsDetailPage,
        commonSettings,
        settings,
        car.dealer,
        dispatch,
        () => trackAemUsedCompareCtaClick("uc-vehicle-request"),
        localContactDealerClick,
    ) || { label: "", click: null };

    return (
        <CompareItemStyles.Wrapper>
            <Compare.Actions>
                {settings.enableContactDealerForm && label && click && (
                    <ButtonPrimary onClick={click}>{label}</ButtonPrimary>
                )}
                <ButtonSecondary
                    href={getCarUrl(settings.urlUsedCarsDetailPage)}
                    onClick={() => {
                        trackAemUsedCompareCtaClick("uc-detail-page");
                    }}
                >
                    <Text ellipsis>
                        <CompareUSCLabel label="usedCompareButtonSeeCar" />
                    </Text>
                </ButtonSecondary>
            </Compare.Actions>
        </CompareItemStyles.Wrapper>
    );
};

const UsedCtaContainer = (): JSX.Element => {
    const cars = useCompareUSCSelector(({ usedCarCompareExternalData }) => usedCarCompareExternalData.cars);
    const hidePrintButton = useCompareUSCSelector((state) => state.compareUscSettings.hidePrintButton);

    return (
        <>
            <CompareTable
                items={cars}
                columnChild={(car) => {
                    if (car.loading) {
                        return (
                            <CompareItemStyles.Wrapper>
                                <Preloader.Wrapper width={1} position="relative">
                                    <Preloader.default />
                                </Preloader.Wrapper>
                            </CompareItemStyles.Wrapper>
                        );
                    }

                    return <CarCTA car={car} />;
                }}
            />

            {!hidePrintButton && <PdfLink />}
        </>
    );
};

export default UsedCtaContainer;
