import styled, { css } from "styled-components";
import { textAlign } from "styled-system";
import { getDirection } from "../../themes/common";

export const Wrapper = styled.div<{ onlyOneOption?: boolean; isLoading?: boolean }>`
    position: relative;
    padding: 5px 0;
    ${textAlign};
    cursor: ${({ onlyOneOption, isLoading }) => (onlyOneOption || isLoading ? "default" : "pointer")};
    svg {
        display: flex;
        flex-shrink: 0;
        @media print {
            display: none;
        }
    }
`;

export const WrapperDarkVariant = styled.div`
    position: relative;
    height: 46px;
    margin-bottom: 20px;
    font-size: 1.1rem;
    background: ${({ theme }) => theme.color.grey1};
    border-radius: 4px;
`;

export const Toggle = styled.a<{ isOpen: boolean }>`
    display: block;
    position: relative;
    padding: ${({ theme }) => (theme.isRTL ? "11px 18px 15px 30px" : "11px 30px 15px 18px")};
    font-size: 1.5rem;
    text-decoration: none;
    color: ${({ theme }) => theme.color.dark};
    background: ${({ isOpen, theme }) => (isOpen ? theme.color.grey2 : 0)};
    border-radius: ${({ isOpen }) => (isOpen ? "4px 4px 0 0" : "4px")};
    outline: 0;
    cursor: pointer;

    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        ${getDirection("right")}: 10px;
    }

    &:hover {
        text-decoration: none;
    }
`;

export const Items = styled.ul`
    display: block;
    position: absolute;
    top: 100%;
    ${getDirection("left")}: 0;
    width: 100%;
    min-width: 160px;
    margin: 2px 0 0;
    margin-top: 0;
    padding: 0;
    font-size: 1.5rem;
    list-style: none;
    text-align: left;
    background-color: ${({ theme }) => theme.color.lightest};
    border: none;
    border-radius: 0;
    box-shadow: none;
    z-index: 1000;
`;

export const Item = styled.li`
    display: block;
    padding: 11px 18px 15px;
    font-size: 1.5rem;
    color: #333;
    background: ${({ theme }) => theme.color.grey1};
    border: none;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
    &:hover {
        background: ${({ theme }) => theme.color.grey2};
    }
`;

const lexusDropdownTitleStyles = css<{ selected?: boolean; onlyOneOption?: boolean }>`
    font-family: ${({ theme }) => theme.fontFamily.regular};
    font-size: 17px;
    line-height: ${({ theme }) => theme.compare.bar.titleLineHeight};
    color: ${({ theme }) => theme.color.primary};
    border-bottom: 1px solid ${({ selected, theme }) => (selected ? theme.color.primary : theme.color.grey5)};
    ${({ onlyOneOption }) =>
        onlyOneOption &&
        css`
            border-color: transparent;
        `};
`;

export const Title = styled.div<{ selected?: boolean; height?: number; showPointer: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: ${({ height }) => height || 0}px;
    padding-bottom: 5px;
    cursor: ${({ showPointer }) => (showPointer ? "pointer" : "default")};
    ${lexusDropdownTitleStyles};
`;
