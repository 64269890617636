import { ModalMapType } from "../../../../../common-deprecated/types/CommonTypes";
import LexusDeleteCarModal from "./DeleteCarModal";
import LexusReplaceCarModal from "./ReplaceCarModal";
import {
    CompareV2ModalIdsType,
    MODAL_DELETE_CAR,
    MODAL_REPLACE_CAR,
} from "../../../../../shared-logic/features/compare/utils/modalConstants";
import { aemLexusCommonModalMap } from "../../../../../common-deprecated/components/modals/aem/lexus/modals";
import IntegratedUscFormModal from "../../../../shared-components/lexus/modals/integrated-modal/v1/IntegratedUscFormModal";
import { MODAL_USC_FORM } from "../../../../shared-logic/utils/integrated-modal/integratedModalConstants";
import { MODAL_USC_INJECTION, SharedUscModalIdsType } from "../../../../shared-logic/utils/modalConstants";
import UscInjectionModal from "./UscInjectionModal";

export const aemLexusCompareUscModalMap: ModalMapType<CompareV2ModalIdsType & SharedUscModalIdsType> = {
    ...aemLexusCommonModalMap,
    [MODAL_DELETE_CAR]: LexusDeleteCarModal,
    [MODAL_REPLACE_CAR]: LexusReplaceCarModal,
    [MODAL_USC_FORM]: IntegratedUscFormModal,
    [MODAL_USC_INJECTION]: UscInjectionModal,
};
