import React, { SyntheticEvent } from "react";
import * as ButtonStyles from "../../../../styles/v2/lexus/components/Button";
import * as Text from "../../../../styles/v2/lexus/utilities/Text";

type ButtonProps = {
    href?: string;
    rel?: string;
    onClick?: (event: SyntheticEvent<Element, Event>) => void;
    disabled?: boolean;
    fullWidth?: boolean;
    linkWithoutIcon?: boolean; // Only for `a` elements, don't use this for `button` elements
    children: React.ReactNode;
    classNames?: string;
};

const ButtonSecondary = (props: ButtonProps): JSX.Element => {
    const { href, rel, onClick, classNames, disabled, fullWidth, children, linkWithoutIcon } = props;
    return (
        <ButtonStyles.ButtonSecondary mx={0} width={fullWidth ? 1 : "auto"}>
            <Text.Button
                as={href ? "a" : "button"}
                role={href ? "link" : "button"}
                href={href && !disabled ? href : undefined}
                onClick={onClick}
                type={!href ? "button" : undefined}
                fullWidth={fullWidth}
                rel={rel}
                className={[
                    classNames || "",
                    disabled ? "disabled" : "",
                    linkWithoutIcon ? "btn-external-link" : "",
                ].join(" ")}
                disabled={!href && disabled}
                aria-disabled={disabled || undefined}
            >
                <Text.Button className={`t-base-text l-micro-text${disabled ? " btn-disabled" : ""}`}>
                    {children}
                </Text.Button>
            </Text.Button>
        </ButtonStyles.ButtonSecondary>
    );
};

export default ButtonSecondary;
