import React from "react";
import * as Modal from "../../../../styles/v2/lexus/globals/Modal";
import * as ButtonGroup from "../../../../styles/v2/lexus/components/ButtonGroup";
import { OKCancelType } from "../ModalActionViews";
import ButtonPrimary from "../../../aem/lexus/button/ButtonPrimary";
import ButtonSecondary from "../../../aem/lexus/button/ButtonSecondary";
import ButtonTertiary from "../../../aem/lexus/button/ButtonTertiary";

export type MultipleChoiceType = {
    list: {
        id: string;
        label?: string;
        handler?: (index: number) => void;
    }[];
    onCancel: () => void;
    cancelLabel: string;
};

export const MultipleChoice = (props: MultipleChoiceType): JSX.Element => {
    const { list, onCancel, cancelLabel } = props;
    return (
        <Modal.Actions>
            <ButtonGroup.Vertical>
                {list.map((actionItem, index) => {
                    return (
                        <ButtonPrimary
                            key={actionItem.id}
                            onClick={() => actionItem.handler && actionItem.handler(index)}
                            fullWidth
                        >
                            {actionItem.label || ""}
                        </ButtonPrimary>
                    );
                })}
                <ButtonTertiary fullWidth onClick={onCancel}>
                    {cancelLabel}
                </ButtonTertiary>
            </ButtonGroup.Vertical>
        </Modal.Actions>
    );
};

export const OKCancel = (props: OKCancelType): JSX.Element => {
    const { onApprove, approveLabel, onCancel, cancelLabel } = props;
    return (
        <Modal.Actions>
            <ButtonGroup.Horizontal>
                <ButtonPrimary fullWidth onClick={onApprove}>
                    {approveLabel}
                </ButtonPrimary>
                <ButtonSecondary fullWidth onClick={onCancel}>
                    {cancelLabel}
                </ButtonSecondary>
            </ButtonGroup.Horizontal>
        </Modal.Actions>
    );
};
