import React from "react";
import * as ButtonStyles from "../../../../styles/v2/lexus/components/Button";
import * as Text from "../../../../styles/v2/lexus/utilities/Text";

type ButtonProps = {
    href?: string;
    onClick?: (event: any) => void;
    disabled?: boolean;
    fullWidth?: boolean;
    hasIconLeft?: boolean;
    children?: React.ReactNode;
    classNames?: string;
    target?: string;
    rel?: string;
};

/**
 * ButtonWithIcon is a button with an icon (left/right)
 * @props href, onClick, disabled, fullWidth, hasIconLeft, children, classNames, target, rel
 * @example
 * <ButtonWithIcon hasIconLeft>
 *  <Text.IconButton mr={1}>Label</Text.IconButton>
 *  <Icon />
 * </ButtonWithIcon>
 */
const ButtonWithIcon = (props: ButtonProps): JSX.Element => {
    const { href, onClick, disabled, fullWidth, hasIconLeft, children, classNames, target, rel } = props;
    return (
        <ButtonStyles.ButtonWithIcon m={0} p={0} fullWidth={fullWidth}>
            <Text.Button
                as={href ? "a" : "button"}
                role={href ? "link" : "button"}
                href={href && !disabled ? href : undefined}
                type={!href ? "button" : undefined}
                onClick={onClick}
                className={[classNames || "", hasIconLeft ? "icon-left" : "", disabled ? "disabled" : ""].join(" ")}
                disabled={!href && disabled}
                aria-disabled={disabled || undefined}
                target={target}
                rel={rel}
                data-testid="ButtonWithIcon"
            >
                {children}
            </Text.Button>
        </ButtonStyles.ButtonWithIcon>
    );
};

export default ButtonWithIcon;
