import React from "react";
import { useDispatch } from "react-redux";
import ModalBaseView from "../../../../../common-deprecated/components/modals/aem/lexus/ModalBaseView";
import {
    MultipleChoice,
    MultipleChoiceType,
} from "../../../../../common-deprecated/components/modals/aem/lexus/ModalActionViews";
import useMaxNumberToCompare from "../../../../../shared-logic/features/compare/hooks/useMaxNumberToCompare";
import * as Modal from "../../../../../common-deprecated/styles/v2/lexus/globals/Modal";
import * as Type from "../../../../../common-deprecated/styles/v2/lexus/globals/Type";
import { IconWrapper } from "../../../../../common-deprecated/styles/v2/lexus/globals/IconWrapper";
import Icon from "../../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import { CompareUSCDispatchType, useCompareUSCSelector } from "../../../redux/store";
import { usedCompareAddCars } from "../../../../../common-deprecated/features/compare/redux/actions/UsedCarCompareExternalDataActions";
import { useCompareUSCLabel } from "../../../utils/labels";
import { ReplaceCarType } from "../../../../../shared-logic/features/compare/utils/modalConstants";
import { ModalViewType } from "../../../../../common-deprecated/types/CommonTypes";

const LexusReplaceCarModal = (props: ModalViewType<ReplaceCarType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { usedCarToAdd } = modalSettings;

    const usedCars = useCompareUSCSelector((state) => state.usedCarCompareExternalData.cars || []);

    const [loadingLabel, compareModalCancelButton] = useCompareUSCLabel([
        "compareBodyLoading",
        "compareModalCancelButton",
    ]);

    const dispatch = useDispatch<CompareUSCDispatchType>();
    const maxNumberToCompare = useMaxNumberToCompare();

    // We are injecting this label as HTML because there are 2 value fields in this single label
    let carToAddHTML = "";
    let options: MultipleChoiceType["list"] = [];
    if (usedCarToAdd) {
        carToAddHTML = `${usedCarToAdd.product.brand.description} ${usedCarToAdd.product.model}`;
        options = usedCars.map((car, index) => ({
            id: `${car.id}-${index}`,
            label:
                car.product.brand.description && car.product.model
                    ? `${car.product.brand.description} ${car.product.model}`
                    : loadingLabel,
            handler: (replaceIndex: number) => {
                dispatch(usedCompareAddCars([usedCarToAdd], replaceIndex));
                close();
            },
        }));
    }

    const label = useCompareUSCLabel("compareModalReplaceCar", {
        maxNumberToCompare: `<strong>${maxNumberToCompare}</strong>`,
        newCar: carToAddHTML,
    });

    return (
        <ModalBaseView modalIdType={modalSettings.type} onClose={close} show={show} size="sm">
            <Modal.Header>
                <Modal.Button type="button" onClick={close}>
                    <IconWrapper>
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>
            <Modal.Body>
                <Type.Text headingType="base" dangerouslySetInnerHTML={{ __html: label }} />
            </Modal.Body>
            <MultipleChoice list={options} onCancel={close} cancelLabel={compareModalCancelButton} />
        </ModalBaseView>
    );
};

export default LexusReplaceCarModal;
