import styled from "styled-components";
import { getDirection } from "../../../../themes/common";
import { theme } from "../theme/lexusTheme";

const NumberedDisclaimerIcon = styled.span.attrs(() => ({
    className: "l-femto-text",
}))<{ isLight?: boolean; hasMousePointer?: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 0;
    margin-${getDirection("right")}: ${theme.space[1]}px;
    color: ${theme.colors.midnight};
    cursor: ${({ hasMousePointer }) => (hasMousePointer ? "pointer" : "default")};

    &:first-child {
        margin-${getDirection("left")}: 0;
    }

    &:last-child {
        margin-${getDirection("right")}: 0;
    }
`;

export default NumberedDisclaimerIcon;
