import React, { useRef } from "react";
import useCompareToggle from "../../../shared-logic/features/compare/hooks/useCompareToggle";
import UsedCarHeader from "./lexus/UsedCarHeader";
import * as CompareStyles from "./lexus/styles/CompareStyles";
import UsedCarSpecs from "./lexus/specifications/UsedCarSpecs";
import UsedCompareDetails from "./lexus/highlight/UsedCompareDetails";
import UsedCtaContainer from "./lexus/UsedCtaContainer";
import { useDisableBodyScroll } from "../../../common-deprecated/hooks";
import { MAIN_NAV_CLASSNAME_TOYOTA } from "../../../common-deprecated/constants";
import { useCompareUSCSelector } from "../redux/store";
import UnsetZIndexHelper from "../../../common-deprecated/components/UnsetZIndexHelper";
import PageDisclaimers from "./lexus/PageDisclaimers";
import ScrollIndicatorDown from "../../../common-deprecated/components/scroll-indicator/ScrollIndicatorDown";
import { useBreakpoint } from "../../../common-deprecated/themes/common";

const UsedCarCompareView = (): JSX.Element | null => {
    const cars = useCompareUSCSelector((state) => state.usedCarCompareExternalData.cars);
    const standalone = useCompareUSCSelector(({ compareUscSettings }) => compareUscSettings.compareV2Standalone);
    const topOfCompareRef = useRef<HTMLElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);

    const { show, expanded, toggleExpanded } = useCompareToggle({
        topOfCompareRef,
        bodyRef,
        hasCars: !!cars.length,
        standalone,
    });
    const isMobile = useBreakpoint("down", "md");

    useDisableBodyScroll(!expanded);

    // TODO: implement remove modal
    return show ? (
        <CompareStyles.Wrapper expanded={expanded}>
            {/* Not really sure what to replace the MAIN_NAV_CLASSNAME_TOYOTA with? */}
            <UnsetZIndexHelper enabled={expanded} selectors={[MAIN_NAV_CLASSNAME_TOYOTA]} />
            <UsedCarHeader expanded={expanded} toggleExpanded={toggleExpanded} />
            <CompareStyles.Body ref={bodyRef}>
                <span ref={topOfCompareRef} />
                <CompareStyles.Container>
                    <UsedCompareDetails />
                    <UsedCtaContainer />
                    <UsedCarSpecs />
                    <UsedCtaContainer />
                    <PageDisclaimers />
                </CompareStyles.Container>
            </CompareStyles.Body>
            {isMobile && <ScrollIndicatorDown viewportRef={bodyRef} recheckKey={String(expanded)} />}
        </CompareStyles.Wrapper>
    ) : null;
};

export default UsedCarCompareView;
