import styled, { css } from "styled-components";
import { display, space, color, DisplayProps, SpaceProps, typography, TypographyProps, compose } from "styled-system";
import { ThemeTypedColorProps } from "../../themes/common";

const styleProps = compose(display, space, color, typography);
const Text = styled.div<
    { weight?: string; lineThrough?: boolean; ellipsis?: boolean } & DisplayProps &
        SpaceProps &
        TypographyProps &
        ThemeTypedColorProps
>`
    position: relative;

    ${styleProps};

    ${({ lineThrough }) =>
        lineThrough &&
        css`
            text-decoration: line-through;
        `};

    ${({ weight, theme }) =>
        weight === "bold" &&
        css`
            font-family: ${theme.fontFamily.bold};
        `};

    ${({ weight, theme }) =>
        weight === "light" &&
        css`
            font-family: ${theme.fontFamily.light};
        `};

    ${({ ellipsis }) =>
        ellipsis &&
        css`
            display: block;
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        `};
`;

export default Text;
